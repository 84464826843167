// Navigation
const mobileMenu = function() {

    const defaults = {
        selectors: {
            navigation: '.navigation',
            menuButton: '.navigation-trigger'
        },
        classes: {
            open: 'menu-open',
            noScroll: 'no-scroll'
        }
    };

    function init() {
        toggleMenu();
    }

    const toggleMenu = () => {
        const html = document.querySelector('html');
        const navigation = document.querySelector(defaults.selectors.navigation);
        const menuButton = document.querySelector(defaults.selectors.menuButton);

        menuButton.addEventListener('click', () => {

            // prevent page from scrolling when menu is open
            html.classList.toggle(defaults.classes.noScroll);

            // toggle nav-list
            navigation.classList.toggle(defaults.classes.open);
        })
    }

    return {
        init: init
    }
}();

mobileMenu.init();


// A11y Modal
// Get the dialog container HTML element (with the accessor method you want)
const element = document.getElementById('my-dialog');

// Instantiate a new A11yDialog module
const dialog = new A11yDialog(element);

dialog.on('show', function (dialogEl, triggerEl) {
    console.log(dialogEl);
    console.log(triggerEl);
})
